<template>
    <div :class="['wb-select-field','select', selected ? 'select-selected' : '', isMultiple ? 'select-multiple' : '',]" @keyup.esc="showDropdown">
        <div class="label" v-if="showLabel && (selected!=null || multipleSelects.length>0)">{{fieldName}}</div>
        <div :class="['field', bgColor]" @click="showDropdown" @keyup.space="showDropdown"  @keyup.enter="showDropdown" tabindex="0">
            <span v-if="multipleSelects.length == 0">{{ selected ? selected : fieldName }}</span>
            <ul v-else class="chip-list">
                <li class="chip-list--item" v-for="(item, index) in multipleSelects" :key="index">
                    {{ shortenString(item,26) }}<span class="material-icons remove-btn" @click="removeFilter(index)">close</span>
                </li>
            </ul>
            <span class="material-icons" aria-hidden="true">{{ !this.showOptions ? 'expand_more' : 'expand_less' }}</span>
        </div>
        <div v-if="showOptions" class="options">
            <div v-for="(value, index) in values" :key="index" >
                <checkbox v-if="isMultiple" class="checkbox options--item" :name="value.label" :labelText="value.label" :initialValue="checkIfExist(value.value)" :id="`f-${index}`" @checkBoxChanged="selectValue(value)"/>          
                <div v-else @click="chooseValue(value)"><h5 class="options--item">{{ value.label }}</h5></div>
            </div>
        </div>
    </div>
</template>

<script>
    import { createUniqueId, on, off, elementContains } from '@/utils/wb-helper';
    import { shortenString } from '@/utils/helpers';
    export default {
        name: 'WBSelectField',
        components: {
            Checkbox: () => import('./Checkbox.vue'),
        },
        props: {
            name: {
                type: String,
                required: true,
            },
            fieldTypeId: {
            default: null,
            },
            showLabel: {
                type: Boolean,
            default: true,
            },
            values: {
                type: Array,
                required: true,
            },
            isMultiple: {
                type: Boolean,
            default: false,
            },
            color: {
                type: String,
            default: '',
            },
            colored: {
                type: Boolean,
            default: false,
            },
            selectedFilters: {
                type: Map,
            default: null,
            }
        },
        data: () => ({
            selected: null,
            multipleSelects: [],
            id: createUniqueId(),
        }),
        watch: {
            selectedFilters: {
                handler() {
                    /*trying to set preset values*/
                    if(this.selectedFilters!==null){
                        var res = this.selectedFilters.get(this.fieldTypeId);
                        if(res!==null && res !== undefined){
                            this.multipleSelects = res;
                        }
                    }     
                },
                immediate: true
            }
        },
        mounted() {
            for (let index = 0; index < this.values.length; index++) {
                if (this.values[index].selected) {
                    this.selected = this.values[index].label;
                }
            }
        },
        computed: {
            fieldName(){
                if(this.name !== null && this.name !== ""){
                    return this.name.replace('Kiel.RefugeeCompass - ','');
                }
                return this.name;
            },
            bgColor() {
                if (this.colored) {
                    if (this.selected === 'Kitafinder') {
                        return 'purple';
                    } else if (this.selected === 'Schulfinder') {
                        return 'orange';
                    } else if (this.selected === 'Freizeit') {
                        return 'primary'
                    } else if (this.selected === 'Beratung & Hilfe') {
                        return 'meganta'
                    } else if (this.selected === 'Weiterbildung & Beruf') {
                        return 'jade'
                    }
                }

                return '';
            },
            isOpened() {
                return this.$store.state.isOpened;
            },
            showOptions: function () {
                return this.id === this.$store.state.optionId;
            },
        },
        methods: {
            shortenString,
            removeFilter(index) {
                this.multipleSelects.splice(index, 1);
                this.$emit('on-select', this.fieldTypeId, this.multipleSelects);
            },
            chooseValue: function(value) {
                this.$emit('on-select', this.fieldTypeId, this.multipleSelects);
                this.selected = value.label;
                this.hideOption();
            },
            checkIfExist: function(value) {
                let index = this.multipleSelects.indexOf(value);
                return index >= 0 ? true : false;
            },
            selectValue: function(value) {
                if (!this.checkIfExist(value.value)) {
                    this.multipleSelects.push(value.value);
                } else {
                    let index = this.multipleSelects.indexOf(value.label);
                    this.multipleSelects.splice(index, 1);
                }
                this.$emit('on-select', this.fieldTypeId, this.multipleSelects);
            },
            showDropdown: function() {
                if (!this.showOptions) {
                    this.$store.state.optionId = this.id;
                    this.addEvents();
                } else {
                    this.hideOption();
                }
            },

            hideOption: function() {
                this.$store.state.optionId = null;
                this.removeEvents();
            },

            addEvents: function() {
            // on(this.$el, 'click', this.onClick);
                on(document, 'click', this.onDocumentClick);
            },
            removeEvents: function() {
            // off(this.$el, 'click', this.onClick);
                off(document, 'click', this.onDocumentClick);
            },
            onClick: function(e) {
                e.stopPropagation();
            },
            onDocumentClick: function(e) {
                if (elementContains(this.$el, e.target)) return;

                this.hideOption();
            },
        }
    }
</script>

<style lang="scss">
    .wb-select-field .checkbox .label{
        width: 100%;
        margin-bottom: 0px;
        margin-left: 30px;
    }

</style>
<style lang="scss" scoped>
    @import '@/scss/_variables.scss';
    @import '@/scss/_mixins.scss';

    .select{
        position: relative;
        display: flex;
        flex-direction: column;

        .label{
            color: #686B6F;
            font-weight: 400;
            margin-left: 5px;
            margin-bottom: 5px;
        }

        .field {
            position: relative;
            display: flex;
            justify-content: space-between;
            align-items: center;
            height: 42px;
            width: 100%;
            padding: 0.375rem 1rem;
            font-size: 16px;
            font-weight: 400;
            line-height: 1.5;
            background-clip: padding-box;
            background: #fff;
            border-radius: 5px;
            transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
            border: 1px solid rgba(58, 58, 58, 0.1);
            color: #686B6F;
            cursor: pointer;
        }

        &-selected {
            .field {
                background: $primary;
                color: $white-color;
                font-weight: 600;

                &.purple {
                    background: $wb-purple;
                }

                &.orange {
                    background: $wb-orange;
                }

                &.meganta {
                    background: $wb-meganta;
                }

                &.jade {
                    background: $wb-jade;
                }

                &.primary {
                    background: $primary;
                }
            }
        }

        &-multiple {
            .field {
                min-height: 42px;
                height: auto;

                .chip-list {
                    margin-left: -10px;
                    &--item {
                        display: inline-flex;
                        align-items: center;
                        background: $primary;
                        color: $white-color;
                        padding: 5px 12px;
                        margin-right: 4px;
                        margin-bottom: 2px;
                        border-radius: 50px;
                        font-size: 13px;
                        text-align: center;
                        word-break: break-word;
                    }
                }
            }
        }

        .options {
            width: 100%;
            display: flex;
            flex-direction: column;
            top: 42px;
            z-index: 9999;
            background: #fff;
            box-shadow: 2px 4px 6px 0px #00000040;
            max-height: 320px;
            overflow-y: auto;

            h5 {
                font-weight: 400;
                font-size: 14px;
            }

            .form-check-label{
                width:100%;
            }

            &--item {
                width: 100%;
                margin-right: 0px;
                cursor: default;
                padding: 15px 1rem;

                .form-check {
                    align-items: flex-start;
                    display: flex;
                    flex-direction: column;
                }

                .more {
                    margin-left: 40px;
                    .form-check-inline {
                        align-items: flex-start;
                        display: flex;
                        flex-direction: column;

                        &:first-child{
                            margin-top: 25px;
                        }
                    }
                }

                &:hover {
                    background: rgba(58, 58, 58, 0.15);
                }

                &:last-child {
                    margin-bottom: 0;
                }
            }
        }
    }

    .remove-btn {
        position: relative;
        color: #000;
        background-color: #FFF;
        border-radius: 5px;
        border: 1px solid;
        top: 1px;
        left: 5px;
        font-size: 18px;
    }

    body[dir="rtl"] {
        .select {
            .options {
                &--item {
                    .more {
                        margin-left: 0;
                        margin-right: 40px;
                    }
                }
            }
        }
    }
</style>